import { pageBaseStatus } from "../pageBaseStatus";
import { actionNameCreator } from "../state-helpers/actionTypeNameCreator";
const iniState = {
    status: pageBaseStatus,
    dm_truongs: []
}
export const truongActionTypes = {
    LOAD: actionNameCreator.TRUONG("LOAD"),
}
export const truongReducer = (state = iniState, action) => {
    switch (action.type) {
        case truongActionTypes.LOAD.START:
            return {
                ...state,
                status: pageBaseStatus.is_loading,
                dm_truongs: undefined
            }
        case truongActionTypes.LOAD.SUCESS:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                dm_truongs: action.payload
            }
        case truongActionTypes.LOAD.ERROR:
            return {
                ...state,
                status: pageBaseStatus.is_completed,
                dm_truongs: []
            }
        default:
            return state;
    }
}
